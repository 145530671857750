<template>
  <div class="welcome">
    <h1>SchoolARS</h1>
    <p>Der einfache Weg für die Online-Abstimmung in der Schule</p>
    <p>
      Es braucht nichts weiter als die ausgedruckten und ausgeschnittenen Marker. 
      Die Schüler bekommen die Frage gestellt und halten die Marker nach oben, wobei die gewählte Antwort
      das Feld ist welches ganz oben steht.
    </p>
    <p>
      Der Lehrer scannt den vorbereiteten QR-Code auf der Chart-Seite und scannt danach die 
      Karten der Schüler. Unser Scanner erfasst automatisch welche Antowrt nach oben gehalten wird
      und aktualisiert die Chartdarstellung, die bspw. in einer Präsentation eingebunden ist.
    </p>
    <p>
      Wenn alle Karten gelesen sind, stellt das Diagramm die Antworten einfach grafisch dar
    </p>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
