import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/chart/:votingid?',
    name: 'Chart',
    component: () => import(/* webpackChunkName: "chart" */ '../views/Chart.vue')
  },
  {
    path: '/scan',
    name: 'Scan',
    component: () => import(/* webpackChunkName: "scan" */ '../views/ScanVoting.vue')
  },
  {
    path: '/scan/:votingid',
    name: 'Scan',
    component: () => import(/* webpackChunkName: "scan" */ '../views/ScanCards.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
